import {initDomPolyfills} from "./polyfills";
import {initSlider} from "./slider";
import {initHeader} from "./header";
import {initLinksHrefTickets} from './a[href="#tickets"]';
import {initLinkPromoSubscribe} from './a[href="#promo-subscribe"]';
import {initForms} from "./forms";
import {initPayment} from "./payment";
import {initParallax} from "./parallax";

initDomPolyfills();

initHeader();

Array.from( document.querySelectorAll(`.modules__list`) ).forEach( ( slider ) => initSlider( slider ));
initSlider( document.querySelector(`.reviews__list`) );
initLinksHrefTickets();
initLinkPromoSubscribe();

initForms();

initPayment();

initParallax();