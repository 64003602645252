let isSliderOnScroll = false;
let isPageOnScroll = false;
let altModulesSlider;

export const swipe = ( element ) => (
    (startEvt) => {
        const isTouch = startEvt.type === `touchstart`;

        let startX = isTouch ? startEvt.touches[0].clientX : startEvt.clientX;
        let startY = isTouch ? startEvt.touches[0].clientY : startEvt.clientY ;
        let moveY;
        let moveX;
        let shiftY;
        let shiftX;

        let isModules = ( element.classList.contains(`modules__list_top`) || element.classList.contains(`modules__list_bottom`) ) && document.documentElement.clientWidth >= 1024 ;

        if ( isModules )
        {
            altModulesSlider = document.querySelector(`.modules__list_bottom`);
            element = document.querySelector(`.modules__list_top`);
        }

        const prevShift = element.style.transform.replace(/[^-.0-9]/g, ``) ? parseFloat( element.style.transform.replace(/[^-.0-9]/g, ``) ) : 0;
        const containerWidth = element.parentElement.offsetWidth;
        const elementWidth = element.offsetWidth;
        const childWidth = element.firstElementChild.offsetWidth;
        const childMarginRight = parseFloat(window.getComputedStyle(element.firstElementChild, null).getPropertyValue(`margin-right`));
        const childShift = childWidth + childMarginRight;
        const childrenCount = Math.ceil(elementWidth / childShift);
        const childrenVisibleCount = Math.round(containerWidth / childShift);
        const maxShiftCount = childrenCount - childrenVisibleCount;

        const onTouchMove = (moveEvt) =>
        {
            document.body.classList.add(`slider-grabbing`);
            element.classList.add(`slider-grabbing`);

            moveX = moveEvt.type === `touchmove` ? moveEvt.touches[0].clientX : moveEvt.clientX;
            moveY = moveEvt.type === `touchmove` ? moveEvt.touches[0].clientY : moveEvt.clientY;
            shiftX = moveX - startX;
            shiftY = moveY - startY;

            if ( ( Math.abs(shiftY) > Math.abs(shiftX) || isPageOnScroll ) && !isSliderOnScroll )
            {
                isPageOnScroll = true;
                return;
            }

            moveEvt.preventDefault();

            isSliderOnScroll = true;

            element.style.transform = `translateX(${shiftX + prevShift}px)`;
            element.style.transition = `none`;

            // const progress = (shiftX + prevShift) * 100 / (elementWidth - containerWidth);
            // const formattedProgress = progress < -100 ? 100 : progress > 0 ? 0 : Math.abs( progress );

            if ( isModules )
            {
                altModulesSlider.classList.add(`slider-grabbing`);

                altModulesSlider.style.transform = `translateX(${shiftX + prevShift}px)`;
                altModulesSlider.style.transition = `none`;
            }

            let progressBar;
            let prevButton;
            let nextButton;

            if ( element.classList.contains(`modules__list`) && !isModules )
            {
                const pos = element.classList[element.classList.length - 2].replace(new RegExp(`${element.classList[0]}_`),``);
                progressBar = element.parentElement.querySelector(`.modules__nav-wrapper_${pos} .modules__bar`);
                prevButton = element.parentElement.querySelector(`.modules__nav-wrapper_${pos} .modules__nav-button_prev`);
                nextButton = element.parentElement.querySelector(`.modules__nav-wrapper_${pos} .modules__nav-button_next`);
                // element.parentElement.querySelector(`.modules__nav-wrapper_${pos} .modules__bar`).style.width = `${formattedProgress}%`;
                // element.parentElement.querySelector(`.modules__nav-wrapper_${pos} .modules__bar`).style.transition = `none`;
                // element.parentElement.querySelector(`.modules__nav-wrapper_${pos} .modules__nav-button_prev`).disabled = prevShift + shiftX >= 0;
                // element.parentElement.querySelector(`.modules__nav-wrapper_${pos} .modules__nav-button_next`).disabled = prevShift + shiftX <= maxShiftCount * childShift * -1;
            } else
            {
                progressBar = element.parentElement.querySelector(`[class*="progress-bar"] [class*="bar"]`);
                prevButton = element.parentElement.querySelector(`[class*="nav-button_prev"]`);
                nextButton = element.parentElement.querySelector(`[class*="nav-button_next"]`);
                // element.parentElement.querySelector(`[class*="progress-bar"] [class*="bar"]`).style.width = `${formattedProgress}%`;
                // element.parentElement.querySelector(`[class*="progress-bar"] [class*="bar"]`).style.transition = `none`;
                // element.parentElement.querySelector(`[class*="nav-button_prev"]`).disabled = prevShift + shiftX >= 0;
                // element.parentElement.querySelector(`[class*="nav-button_next"]`).disabled = prevShift + shiftX <= maxShiftCount * childShift * -1;
            }

            const barWidthPercent = Math.round(progressBar.offsetWidth * 100 / progressBar.parentElement.offsetWidth);
            const progressBarShift = prevShift + shiftX < maxShiftCount * childShift * -1 ?  101 : prevShift + shiftX > 0 ? 0 : Math.round((prevShift + shiftX) * 100 / (maxShiftCount * childShift));
            // console.log(progressBarShift)
            // console.log(progressBarShift * (progressBar.parentElement.offsetWidth - progressBar.offsetWidth) / 100 );
            progressBar.style.left = `${Math.abs(progressBarShift * (progressBar.parentElement.offsetWidth - progressBar.offsetWidth) / 100)}px`;
            progressBar.style.transition = `none`;
            prevButton.disabled = prevShift + shiftX >= 0;
            nextButton.disabled = prevShift + shiftX <= maxShiftCount * childShift * -1;
        };

        const onTouchUp = (upEvt) =>
        {
            let finalShift;
            element.style.transition = ``;
            isSliderOnScroll = false;
            isPageOnScroll = false;

            document.body.classList.remove(`slider-grabbing`);
            element.classList.remove(`slider-grabbing`);

            if ( prevShift + shiftX > 0 )
            {
                finalShift = 0;
                element.style.transform = `translateX(${finalShift}px)`;
            } else if ( Math.abs(prevShift + shiftX ) > maxShiftCount * childShift)
            {
                finalShift = maxShiftCount * childShift * -1;
            } else
            {
                finalShift = prevShift + shiftX;
            }

            element.style.transform = `translateX(${finalShift}px)`;

            if ( isModules )
            {
                altModulesSlider.classList.remove(`slider-grabbing`);

                altModulesSlider.style.transition = ``;
                altModulesSlider.style.transform = `translateX(${finalShift}px)`;
            }

            const event = document.createEvent(`Event`);
            event.initEvent(`click`, true, true);
            let button;

            if ( element.classList.contains(`modules__list`) && !isModules )
            {
                const pos = element.classList[element.classList.length - 1].replace(new RegExp(`${element.classList[0]}_`),``);
                element.parentElement.querySelector(`.modules__nav-wrapper_${pos} .modules__bar`).style.transition = ``;

                button = startX > moveX ? element.parentElement.querySelector(`.modules__nav-wrapper_${pos} [class*="nav-button_next"]`) : element.parentElement.querySelector(`.modules__nav-wrapper_${pos} [class*="nav-button_prev"]`);
            } else
            {
                element.parentElement.querySelector(`[class*="progress-bar"] [class*="bar"]`).style.transition = ``;

                button = startX > moveX ?  element.parentElement.querySelector(`[class*="nav-button_next"]`) : element.parentElement.querySelector(`[class*="nav-button_prev"]`);
            }

            button.dispatchEvent(event);

            document.removeEventListener(isTouch ? `touchmove` : `mousemove`, onTouchMove, {passive: false});
            document.removeEventListener(isTouch ? `touchend` : `mouseup`, onTouchUp, {passive: false});
        };

        document.addEventListener(isTouch ? `touchmove` : `mousemove`, onTouchMove, {passive: false});
        document.addEventListener(isTouch ? `touchend` : `mouseup`, onTouchUp, {passive: false});
    }
);