import jump from "jump.js";

export const initLinkPromoSubscribe = () => {
	const links = Array.from(document.querySelectorAll(`[href="#promo-subscribe"]`));

	if ( links.length )
	{
		links.forEach( ( el ) => el.addEventListener(`click`, ( evt ) => {
			evt.preventDefault();

			const id = el.href.split(`#`)[1];
			const component = document.querySelector(`[id="${id}"]`);

			jump(component, {
				duration: 700,
				offset: 0,
			})
		}) );
	}
};