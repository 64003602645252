import {initWidget} from "./initWidget";

const attributeSolo = `data-solo-properties`;
const attributeVip = `data-vip-properties`;

let dataSolo;
let dataVip;

export const initPayment = () => {
	if ( document.querySelector(`[data-payment-type]`) )
	{
		const nodeSolo = document.querySelector(`[${attributeSolo}]`);
		dataSolo = JSON.parse(nodeSolo.getAttribute(attributeSolo));
		nodeSolo.removeAttribute(attributeSolo);

		const nodeVip = document.querySelector(`[${attributeVip}]`);
		dataVip = JSON.parse(nodeVip.getAttribute(attributeVip));
		nodeVip.removeAttribute(attributeVip);

		const script = document.createElement(`script`);
		script.src = `https://widget.cloudpayments.ru/bundles/cloudpayments`;

		script.addEventListener(`load`, () => {
			document.addEventListener(`click`, ({target}) => {
				const attr = target.getAttribute(`data-payment-type`);

				if ( attr )
				{
					attr === `solo` ? initWidget(dataSolo, `solo.html`) : initWidget(dataVip, `vip.html`);
				}
			});
		});

		document.body.appendChild(script);
	}
};