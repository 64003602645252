import jump from "jump.js";

export const initLinksHrefTickets = () => {
	const links = Array.from(document.querySelectorAll(`[href="#tickets"]:not(.header__button):not(.header__link)`));

	if ( links.length )
	{
		links.forEach( ( el ) => el.addEventListener(`click`, ( evt ) => {
			evt.preventDefault();

			const id = el.href.split(`#`)[1];
			const component = document.querySelector(`[id="${id}"]`);

			jump(component, {
				duration: 700,
				offset: component.getBoundingClientRect().height - document.documentElement.clientHeight,
			})
		}) );
	}
};