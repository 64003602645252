export const initWidget = (data, page) => {
	const widget = new cp.CloudPayments();

	widget.charge({ // options
			publicId: data.publicId,  //id из личного кабинета
			invoiceId: data.invoiceId,
			description: data.description, //назначение
			amount: parseFloat(data.amount), //сумма
			currency: data.currency, //валюта
			requireEmail: JSON.parse(data.requireEmail.toLowerCase()),
			skin: data.skin, //дизайн виджета
		},
		function (options) { // success
			location = `${location.origin}/${page}`;//действие при успешной оплате
		},
		function (reason, options) { // fail
			//действие при неуспешной оплате
		});
};