import jump from "jump.js";

let scrollPosition = 0;

export const initHeader = () => {
	const header = document.querySelector(`.header`);

	if ( header )
	{
		const anchors = Array.from(document.querySelectorAll(`.header__link, .header__button`));
		const nav = document.querySelector(`.header__nav`);
		const button = document.querySelector(`.header__show-button`);

		button.addEventListener(`click`, () => {
			header.classList.toggle(`header_show`);
			nav.classList.toggle(`header__nav_show`);
			button.classList.toggle(`header__show-button_close`);

			if ( header.classList.contains(`header_show`) )
			{
				scrollPosition = window.pageYOffset;

				document.documentElement.style.overflow = `hidden`;
				document.body.style.overflow = `hidden`;
				document.body.style.width = `100%`;
				document.body.style.position = `fixed`;
				document.body.style.top = `${scrollPosition * -1}px`;
				document.body.style.left = `0`;
			} else
			{
				document.documentElement.style = ``;
				document.body.style = ``;

				window.scrollTo(0, scrollPosition);
			}
		});

		anchors.forEach( ( el ) => el.addEventListener(`click`, ( evt ) => {
			evt.preventDefault();

			const id = el.href.split(`#`)[1];
			const component = document.querySelector(`[id="${id}"]`);

			const event = document.createEvent(`Event`);
			event.initEvent(`click`, true, true);
			button.dispatchEvent(event);

			jump(component, {
				duration: 700,
				offset: id === `tickets` ? component.getBoundingClientRect().height - document.documentElement.clientHeight : 0,
			})
		}) );
	}
};