let altModulesSlider;
// let pbShift = 0;

export const bookmarks = ( element, button ) => (
	() => {
		let isModules = (element.classList.contains(`modules__list_top`) || element.classList.contains(`modules__list_bottom`)) && document.documentElement.clientWidth >= 1024 ;

		if ( isModules )
		{
			altModulesSlider = document.querySelector(`.modules__list_bottom`);
			element = document.querySelector(`.modules__list_top`);
		}

		if ( button.closest(`.modules__nav-wrapper_bottom`) )
		{
			element = document.querySelector(`.modules__list_bottom`);
		}

		const prevShift = element.style.transform.replace(/[^-.0-9]/g, ``) ? parseFloat( element.style.transform.replace(/[^-.0-9]/g, ``) ) : 0;
		const containerWidth = element.parentElement.offsetWidth;
		const elementWidth = element.offsetWidth;
		const childWidth = element.firstElementChild.offsetWidth;
		const childMarginRight = parseFloat(window.getComputedStyle(element.firstElementChild, null).getPropertyValue(`margin-right`));
		const childShift = childWidth + childMarginRight;
		const childrenCount = Math.ceil(elementWidth / childShift);
		const childrenVisibleCount = Math.round(containerWidth / childShift);
		const maxShiftCount = childrenCount - childrenVisibleCount;

		const test1 = prevShift % childShift;
		const isNext = !!Array.from(button.classList).find((el) => /next/.test(el));
		const shift = isNext ? prevShift - test1 - childShift : test1 ? prevShift - ( childShift + test1 ) + childShift : prevShift + childShift;

		if ( button.parentElement.querySelector(`[disabled]`) )
		{
			button.parentElement.querySelector(`[disabled]`).disabled = false;
		}

		if ( shift >= 0 || Math.abs(shift) >= maxShiftCount * childShift )
		{
			button.disabled = true;
		}

		if ( shift > 0 || Math.abs(shift) > maxShiftCount * childShift )
		{
			return;
		}

		element.style.transform = `translateX(${shift}px)`;

		if ( isModules )
		{
			altModulesSlider.style.transform = `translateX(${shift}px)`;
		}

		// const progress = shift * 100 / (maxShiftCount * childShift);
		// const formattedProgress = progress < -100 ? 100 : progress > 0 ? 0 : Math.abs( progress );
		// button.parentElement.querySelector(`[class*="progress-bar"] [class*="bar"]`).style.width = `${formattedProgress}%`;

		const progressBar = button.parentElement.querySelector(`[class*="progress-bar"] [class*="bar"]`);
		// const barWidthPercent = Math.round(progressBar.offsetWidth * 100 / progressBar.parentElement.offsetWidth);
		const progressBarShift = Math.round(shift * 100 / (maxShiftCount * childShift));
		const test = Math.abs(progressBarShift * (progressBar.parentElement.offsetWidth - progressBar.offsetWidth) / 100);
		progressBar.style.left = `${ isNext ? test + 1 : test}px`;




		// const progressBar = button.parentElement.querySelector(`[class*="progress-bar"]`);
		// const bar = progressBar.firstElementChild;
		// pbShift = isNext ? ++pbShift : --pbShift;
		// // debugger
		// bar.style.transform = `translateX(${pbShift * Math.round(progressBar.offsetWidth / childrenCount)}px)`;
	}
);