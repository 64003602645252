import {swipe} from "./swipe";
import {reset} from "./reset";
import {bookmarks} from "./bookmarks";

let modulesBookmarksInit = false;

export const initSlider = ( element ) => {
    if ( element )
    {
        element.addEventListener(`touchstart`, swipe( element ), {passive: false});
        element.addEventListener(`mousedown`, swipe( element ));

        if (!modulesBookmarksInit && element.classList.contains(`modules__list`) || element.classList.contains(`reviews__list`))
        {
            Array.from( element.parentElement.querySelectorAll(`[class*="nav-wrapper"] [class*="nav-button"]`)).forEach( (el) => el.addEventListener(`click`, bookmarks( element, el )) );
            modulesBookmarksInit = true;
        }

        window.addEventListener( `resize`, reset( element ));
    }
};