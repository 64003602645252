export const initParallax = () => {
	const shifts = [2, 4.5, 8.9, 1.7, 5.3];
	const promo = document.querySelector(`.promo`);
	const promoPhotos = Array.from(document.querySelectorAll(`.promo__img`));
	const photos = document.querySelector(`.photos`);
	const photosPhotos = Array.from(document.querySelectorAll(`.photos__img`));
	const footer = document.querySelector(`.footer`);

	if (promo || photos || footer)
	{
		window.addEventListener(`scroll`, () => {
			if ( promo )
			{
				const promoPos = promo.getBoundingClientRect();

				if ( promoPos.top + promoPos.height > 0)
				{
					promoPhotos.forEach((photo, i) => photo.style.transform = `translateY(${-window.pageYOffset / shifts[i%shifts.length]}px)`);
				}
			}

			if ( photos && footer )
			{
				const photosPos = photos.getBoundingClientRect();
				const footerPos = footer.getBoundingClientRect();

				if ( photosPos.top - document.documentElement.clientHeight < 0 )
				{
					photosPhotos.forEach((photo, i) => photo.style.transform = `translateY(${( footerPos.top + footerPos.height - document.documentElement.clientHeight )  / shifts[i%shifts.length]}px)`);
				}
			}
		});
	}
};